import React, { useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/layout';
import { SongConsumer } from '../components/context';
import AudioControl from '../components/audio-control';
import '../assets/styles/pages/index.scss';

/* global window */
let bgInterval = null;
export const query = graphql`
  query HomeQuery {
    home: datoCmsHome {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      slideshowInterval
      slide {
        ... on DatoCmsEdition {
          id
          title
          name
          slug
          trackId
          category {
            category
          }
          soundFile
          featured {
            url
            alt
          }
        }
        ... on DatoCmsAction {
          id
          actionType
          date
          slug
          title
          name
          location
          featured {
            url
            alt
          }
        }
      }
    }
  }
`;

const changeBgImage = (direction) => {
  if (typeof window !== 'undefined') {
    const $activeSlide = window.document.getElementsByClassName('js-slide active');
    if ($activeSlide.length > 0) {
      let $nextSlide = $activeSlide[0].nextSibling;

      if (direction === 'prev') {
        $nextSlide = $activeSlide[0].previousSibling;
      }

      $activeSlide[0].classList.remove('active');
      if ($nextSlide === null) {
        $nextSlide = window.document.getElementsByClassName('js-slide');

        if (direction === 'prev') {
          $nextSlide[window.document.getElementsByClassName('js-slide').length - 1].classList.add('active');
        } else {
          $nextSlide[0].classList.add('active');
        }
      } else {
        $nextSlide.classList.add('active');
      }
    }
  }
};

const IndexPage = ({ data }) => {
  const nextSlide = () => {
    changeBgImage();
    clearInterval(bgInterval);
  };

  const prevSlide = () => {
    changeBgImage('prev');
    clearInterval(bgInterval);
  };

  useEffect(() => {
    bgInterval = setInterval(() => {
      if (typeof window !== 'undefined') {
        changeBgImage();
      }
    }, data.home.slideshowInterval);
  }, [query]);

  const addActiveClass = (index) => {
    if (index === 0) {
      return 'active';
    }

    return '';
  };

  const displaySlideDetails = (slide, context) => {
    if (slide.soundFile) {
      return (
        <>
          <div className="one-quarter">
            <Link to={`/editions/${slide.slug}`}>
              {slide.category.category}
            </Link>
          </div>
          <div className="two-quarters">
            {slide.soundFile && (
              <div className="audio-control-wrapper">
                <AudioControl edition={slide} context={context} />
                <Link to={`/editions/${slide.slug}`} className="song-info">
                  <strong>
                    {slide.name}
                    <br />
                    <em>{slide.title}</em>
                  </strong>
                </Link>
              </div>
            )}
          </div>
        </>
      );
    }

    return (
      <>
        <div className="one-quarter">
          <Link to={`/actions/${slide.slug}`}>
            {slide.actionType}
            {' '}
            {slide.date}
            <br />
            {slide.location}
          </Link>
        </div>
        <div className="two-quarters">
          <Link to={`/actions/${slide.slug}`}>
            <strong>
							{slide.name && (
							<span>
              {slide.name}
							<br />
							</span>
							)}
              <em>{slide.title}</em>
            </strong>
          </Link>
        </div>
      </>
    );
  };

  const slideLink = (slide) => {
    if (slide.soundFile) {
      return `/editions/${slide.slug}`;
    }

    return `/actions/${slide.slug}`;
  };

  return (
    <SongConsumer>
      {(context) => (
        <Layout>
          <HelmetDatoCms seo={data.home.seoMetaTags} />
          <div className="container">
            <div className="slide-container">
              <div className="slide-wrapper">
                {
                  data.home.slide.map((slide, index) => (
                    <div className={`slide js-slide ${addActiveClass(index)}`} key={slide.id}>
                      <div className="slide-details">
                        {displaySlideDetails(slide, context)}
                      </div>
                      {slide.featured && (
                        <Link to={slideLink(slide)}>
                          <img src={slide.featured.url} alt={slide.featured.alt} />
                        </Link>
                      )}
                    </div>
                  ))
                }
              </div>
              <div
                className="slide-prev"
                role="button"
                tabIndex={0}
                aria-label="previous"
                onClick={(e) => {
                  e.preventDefault();
                  prevSlide();
                }}
                onKeyDown={prevSlide}
              />
              <div
                className="slide-next"
                role="button"
                tabIndex={-1}
                aria-label="next"
                onClick={(e) => {
                  e.preventDefault();
                  nextSlide();
                }}
                onKeyDown={nextSlide}
              />
            </div>
          </div>
        </Layout>
      )}
    </SongConsumer>
  );
};

IndexPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.shape()).isRequired,
};

export default IndexPage;
